* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #151C1C;
  color: #ebebeb;
  font-family: 'Bai Jamjuree', sans-serif;
}

a {
  text-decoration: none;
}

